<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>惠链接</el-breadcrumb-item>
                    <el-breadcrumb-item>订单查询</el-breadcrumb-item>
                    <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form label-width="150px" ref="form" :model="form">
            <div class="admin_main_block admin_m15">
                <div class="admin_main_block_top">
                    <div class="admin_main_block_right">
                        <el-row>
                            <el-col :span="12">
                                <el-button type="primary" @click="success('form')">确认</el-button>
                            </el-col>
                            <el-col :span="12">
                                <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="admin_form_main">
                    <el-form-item label="用户手机号" prop="account"
                                  :rules="[{ required: true, message: '请填写用户手机号', trigger: 'blur' },]">
                        <el-input type="text" placeholder="请填写用户手机号" :disabled="disabled" maxlength="15"
                                  v-model="form.account"></el-input>
                    </el-form-item>
                    <el-form-item label="用户姓名" prop="username"
                                  :rules="[{ required: true, message: '请填写用户姓名', trigger: 'blur' },]">
                        <el-input type="text" placeholder="请填写用户姓名" :disabled="disabled" maxlength="10"
                                  v-model="form.username"></el-input>
                    </el-form-item>
                    <!--<el-form-item label="推荐人手机号" prop="referAccount"
                                  :rules="[{ required: true, message: '请填写推荐人手机号', trigger: 'blur' },]">
                        <el-input type="text" placeholder="请填写推荐人手机号" :disabled="disabled"
                                  v-model="form.referAccount"></el-input>
                    </el-form-item>-->
                    <el-form-item label="推荐人手机号">
                        <el-input type="text" placeholder="请填写推荐人手机号" :disabled="disabled"
                                  v-model="form.referAccount"></el-input>
                    </el-form-item>

                </div>
            </div>
        </el-form>
    </div>
</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                span8: 8,
                title: "新增",
                disabled:false,
                form: {
                    account:'',
                    username:'',
                    referAccount:'',
                },
            }
        },
        created() {
            this.form.id = this.$route.query.id;
            if (this.form && this.form.id) {
                this.title = "编辑";
            }
        },
        methods: {
            success(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let url = this.$api.awzgs.awzgsUserAdd;
                        console.log("submitForm", this.form)
                        if (this.form.id) {
                            url = this.$api.taskCashEditTask;
                        }
                        this.$post(
                            url,
                            this.form
                        ).then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    type: "success",
                                    message: "成功",
                                });
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.msg,
                                });
                            }
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
